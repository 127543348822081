var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rotate-item"},[_c('div',{staticClass:"lunbo",style:('width: ' +
            _vm.width * _vm.num +
            'rem; margin-left: ' +
            (_vm.line%_vm.width)/2 +
            'rem;transition: transform ' +
            _vm.dur +
            'ms cubic-bezier(0, 0, 0.28, 1); transform: translateX(-' +
            _vm.pos +
            'rem);')},_vm._l((_vm.list),function(value,key){return _c('div',{key:key,class:'item item-lv'+value.lv,style:('width:'+_vm.width+'rem')},[_c('img',{attrs:{"src":value.cover,"alt":""}}),_c('p',{staticClass:"prop-name"},[_vm._v(_vm._s(value.name))])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }